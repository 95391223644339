/* ============================================================================================== */
/* !SECONDARY */

.secondary--default {
    #container {
        padding-top: 300px;
        
        &:after {
            background-image: url('/images/common/mobile--bg--header.png');
            background-position: center top;
            
            @include breakpoint('tablet') {
                background-image: url('/images/common/secondary--bg--header.png');
                background-position: center top;
                padding-top: 170px;
            }
        }
    }
    
    .page--header {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0px 10px;
        
        max-width: 1200px;
        margin: auto;
        
        @include breakpoint('tablet') {
            height: 350px;
        }
        
        @include breakpoint('tablet-wide') {
            height: 300px;
        }
        
        P {
            line-height: 1.5;
        }
        
        .page--intro {
            text-align: left;
        }
    }
    
    .page--title-container {
        .title {
            text-align: center;
            
            @include breakpoint('tablet') {
                text-align: right;
            }
        }
    }
}



.secondary--slim {
    #container {
        padding-top: 300px;
        
        @include breakpoint('tablet') {
            padding-top: 500px;
        }
        
        &:after {
            background-image: url('/images/common/mobile--bg--header.png');
            background-position: center top;
            
            @include breakpoint('tablet') {
                background-image: url('/images/common/secondary--bg--header-slim.png');
            }
        }
    }
}



.section--legal {
    MAIN {
        @include breakpoint('tablet') {
            padding-bottom: 400px;
        }
    }
    
    .content--aside .content .title {
        font-size: 4.0rem;
    }
    
    h1, h2, h3, h4, h5, h6 {
        + P {
            margin-top: 10px !important;
        }
    }
    
    MAIN {
        .content {
            P, UL {
                font-size: 1.8rem;
                line-height: 1.5;
            }
        }
    }
}


.content--article {
    .content {
        P {
            font-size: 2.0rem;
          }
          
        &__list {
            ul {
                list-style: disc outside;
                margin: 1rem 0 0 2.5rem;
            }
          
            ul, span {
                line-height: 1.25;
                font-size: 2.0rem;
            }
        }
        
        &__disclaimer p {
          font-size: 1.4rem;
          font-style: italic;
        }
    }
}