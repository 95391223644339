/* ============================================================================================== */
/* !LINKS */

.link--angle-right {
    display: inline-block;
    
    &:after {
        content: "\f105";
        display: inline-block;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        margin-left: 5px;
    }
}

.link--all-blogs {
  display: block !important;
  width: max-content;

  & i {
    position: relative;
    left: 2px;
  }
}