/* ============================================================================================== */
/* !PANELS */

.panel--cyan {
    background: $pyxus-cyan;
    color: #fff;
    padding: 15px 12px 12px 12px;
}

.panel--shadowed {
    background: #fff;
    -moz-border-radius: 	10px;
    -webkit-border-radius: 	10px;
    border-radius: 			10px;
    -khtml-border-radius: 	10px;
    overflow: hidden;
    
    -webkit-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.25);
    -moz-box-shadow:    0px 0px 30px 0px rgba(0,0,0,0.25);
    box-shadow:         0px 0px 30px 0px rgba(0,0,0,0.25);
    
    &.no-shadow--lt-tablet {
        @include breakpoint('tablet', 'max') {
            -webkit-box-shadow: none;
            -moz-box-shadow:    none;
            box-shadow:         none;
            
            -moz-border-radius: 	0px;
            -webkit-border-radius: 	0px;
            border-radius: 			0px;
            -khtml-border-radius:   0px;
        }
    }
}

.panel--image--shadowed {
    @extend .panel--shadowed;
    
    display: flex;
    align-items: center;
    justify-content: center;
    
    padding: 40px;
}

.panel--body--default {
    padding: 20px;
}