/* ============================================================================================== */
/* !FORMS */

INPUT[type="text"],
INPUT[type="email"],
INPUT[type="number"],
INPUT[type="password"],
INPUT[type="phone"],
INPUT[type="submit"] {
    width: 100%;
    border: 1px solid $pyxus-blue;
    padding: 5px 10px 5px 10px;


    -moz-border-radius: 	5px;
    -webkit-border-radius: 	5px;
    border-radius: 			5px;
    -khtml-border-radius: 	5px;
    overflow: hidden;

    font-family: $font-family--default;
    font-size: $font-size--default;
    color: $grey;

    //height: 40px;
    //line-height: 36px;

    &.error {
        border-color: $pyxus-red;
    }
}

.item {
    &.error {
        LABEL {
            color: $pyxus-red;
        }

        INPUT {
            border-color: $pyxus-red;
        }
    }
}

.submit-form {
    @include breakpoint('tablet', 'max') {
        display: block !important;
    }
}

INPUT[type="submit"] {
    @extend .button;
    -webkit-appearance: none;
    cursor: pointer;
    font-size: 1.6rem;
}

TEXTAREA {
    width: 100%;
    border: 1px solid $pyxus-blue;
    padding: 5px 10px;
    -moz-border-radius: 	5px;
    -webkit-border-radius: 	5px;
    border-radius: 			5px;
    -khtml-border-radius: 	5px;
    overflow: hidden;
    font-size: 1.6rem;

    resize: none;

    &.error {
        border-color: $pyxus-red;
    }
}

LABEL {
    display: block;
    color: $pyxus-blue;
    font-weight: 600;
    margin: 0px 0px 4px 0px;

    &.error {
        margin-top: 10px;
        color: $pyxus-red;
    }
}

SELECT {
    width: 100%;
    height: 40px;
    background: #fff;

    font-family: $font-family--default;
    font-size: $font-size--default;
    color: #000;

    border: 1px solid $pyxus-blue;
    padding: 5px;
    -moz-border-radius: 	5px;
    -webkit-border-radius: 	5px;
    border-radius: 			5px;
    -khtml-border-radius: 	5px;
    overflow: hidden;

    font-size: 1.6rem;

    &.error {
        border-color: $pyxus-red;
    }
}

.required {
    color: $pyxus-red;
}

.form--default {
    .row--full {
        margin: 0px 0px 20px 0px;
    }

    .row {
        display: flex;
        justify-content: flex-start;
        margin: 0px 0px 20px 0px;

        .item {
            margin-right: 40px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

@include breakpoint('tablet-wide') {
    #contact--form {
        padding-right: 100px;
    }
}
