/* ============================================================================================== */
/* !BRANDS */

.section--brands {

}

#brands--logo-gallery {
    .gallery--grid--default {
        position: relative;
        z-index: 2;
        
        .item {
            margin-bottom: 40px;
        }
    }
    
    @include breakpoint('tablet', 'max') {
        -webkit-box-shadow: none;
        -moz-box-shadow:    none;
        box-shadow:         none;
        
        .gallery--grid--default {
            background: #fff;
            -moz-border-radius: 	10px;
            -webkit-border-radius: 	10px;
            border-radius: 			10px;
            -khtml-border-radius: 	10px;
            overflow: hidden;
            
            -webkit-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.25);
            -moz-box-shadow:    0px 0px 30px 0px rgba(0,0,0,0.25);
            box-shadow:         0px 0px 30px 0px rgba(0,0,0,0.25);
        }
    }
}

#brands--feature--sentri {
    @include breakpoint('tablet', 'max') {
        .feature--title {
            text-align: left;
            padding-left: 0;
            padding-right: 0;
        }
    }
    
    @include breakpoint('tablet') {
        .blurb {
            max-width: 66%;
            margin: auto;
            text-align: center;
        }
        
        .cta {
            text-align: center;
        }
    }
    
    @include breakpoint('phone-wide') {
        .copy {
            background: $grey--xxxl;
        }
    }
}