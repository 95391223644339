/* ============================================================================================== */
/* !COLORS */

.pyxus-cyan {
    color: $pyxus-cyan;
}

.pyxus-yellow {
    color: $pyxus-yellow;
}

.pyxus-blue {
    color: $pyxus-blue;
}

.pyxus-red {
    color: $pyxus-red;
}


.lime-green {
    color: $lime-green;
}


.grey--xxxl {
    color: $grey--xxxl;
}

.grey--xl {
    color: $grey--xl;
}
.grey--light {
    color: $grey--light;
}

.white {
    color: #fff;
}




.bg--white {
    background: #fff;
}

.bg--grey--light {
    background: $grey--light;
}

.bg--grey--xxxl {
    background: $grey--xxxl;
}