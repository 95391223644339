/* ============================================================================================== */
/* !MARGINS AND PADDING */

    /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
    /* !TOP */
    
        .margin-top--default {
            margin-top: 20px !important;
        }
        
        .margin-top--xxxl {
            margin-top: 60px !important;
        }
        
        .margin-top--xxl {
            margin-top: 50px !important;
        }
        
        .margin-top--xl {
            margin-top: 40px !important;
        }
        
        .margin-top--lg {
            margin-top: 30px !important;
        }
        
        .margin-top--sm {
            margin-top: 10px !important;
        }
        
            @media(max-width: 599px) {
                .xs--margin-top--default {
                    margin-top: 20px !important;
                }
            }

    
    /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
    /* !BOTTOM */
    
        .margin-bottom--none {
            margin-bottom: 0 !important;
        }
        
        .margin-bottom--sm {
            margin-bottom: 10px !important;
        }
        
        .margin-bottom--default {
            margin-bottom: 20px !important;
        }
        
        .margin-bottom--xxxl {
            margin-bottom: 60px !important;
        }
        
        .margin-bottom--xxl {
            margin-bottom: 50px !important;
        }
        
        .margin-bottom--xl {
            margin-bottom: 40px !important;
        }
        
        .margin-bottom--lg {
            margin-bottom: 30px !important;
        }
    
    
            @media(max-width: 959px) {
                .mobile--margin-bottom--xl {
                    margin-bottom: 40px !important;
                }
            }
            
            
    /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
    /* !LEFT */
        
        .margin-left--xxl {
            margin-left: 50px !important;
        }
        
        .margin-left--xl {
            margin-left: 40px !important;
        }
        
        .margin-left--lg {
            margin-left: 30px !important;
        }
        
        .margin-left--default {
            margin-left: 20px !important;
        }
        
        .margin-left--none {
            margin-left: 0 !important;
        }


/* ============================================================================================== */
/* !GLOBAL | PADDING */
   
    .padding--none {
        padding: 0 !important;
    }
    
    
    /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
    /* !ALL */
        
        .padding-all--xl {
            padding: 40px !important;
        }
        
        .padding-all--default {
            padding: 20px !important;
        }
        
    /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
    /* !TOP */
    
        .padding-top--none {
            padding-top: 0 !important;
        }
    
        .padding-top--xxxl {
            padding-top: 60px !important;
        }
        
        .padding-top--xxl {
            padding-top: 50px !important;
        }
        
        .padding-top--xl {
            padding-top: 40px !important;
        }
        
        
    /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
    /* !BOTTOM */
    
        .padding-bottom--none {
            padding-bottom: 0 !important;
        }
        
        .padding-bottom--xxxl {
            padding-bottom: 60px !important;
        }
        
        .padding-bottom--xxl {
            padding-bottom: 50px !important;
        }
        
        .padding-bottom--xl {
            padding-bottom: 40px !important;
        }
        
        .padding-bottom--default {
            padding-bottom: 20px !important;
        }
        
    /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
    /* !LEFT */
        
        .padding-left--xl {
            padding-left: 40px !important;
        }
        
        .padding-left--default {
            padding-left: 20px !important;
        }
    
    /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
    /* !RIGHT */
    
    /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
    /* !CENTERING CONTENT WITH MARGINS */
    .margin-x--auto {
      margin-left: auto;
      margin-right: auto;
  }

