/* ============================================================================================== */
/* !MIXINS */

$breakpoints: (
    "phone":            $phone,
    "phone-wide":       $phone-wide,
    "phablet":          $phablet,
    "tablet-small":     $tablet-small,
    "tablet":           $tablet,
    "tablet-medium":    $tablet-medium,
    "tablet-wide":      $tablet-wide,
    "desktop-small":    $desktop-small,
    "desktop":          $desktop,
    "desktop-wide":     $desktop-wide
);

@mixin breakpoint($width, $type: min) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);
        @if $type == max {
            $width: $width - 1px;
        }
        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    }
}


$browser-context: 16;

@function em($pixels, $context: $browser-context) {
	@return ($pixels / $context) * 1em;
}