/* ============================================================================================== */
/* !HEADER */

.mainNavigation--open {
    overflow-y: hidden;
    
    #mainHeader {
        @include breakpoint('tablet-wide') {
            display: block;
        }
    }
    
    #mainHeader--container {
        background: $grey--xxxl;
        
        @include breakpoint('tablet-wide') {
            background: transparent;
        }
    }
    
    #mainHeader--navigation-toggle {
        .open {
            display: none;
        }
        
        .close {
            display: block;
        }
    }
}

#mainHeader {
    position: fixed;
    width: 100%;
    max-width: 1400px;
    margin: auto;
    top: 0px;
    left: 0px;
    right: 0px;
    
    color: #fff;
    z-index: 999;
    
    transition: background 0.25s ease, box-shadow 0.25s ease;
    
    &.sticky {
        background: rgba(13, 124, 191, 0.95);
        
        -webkit-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.25);
        -moz-box-shadow:    0px 0px 30px 0px rgba(0,0,0,0.25);
        box-shadow:         0px 0px 30px 0px rgba(0,0,0,0.25);
    }
    
    A {
        color: #fff;
    }
    
    #mainHeader--container {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        padding: 10px 10px;
        max-width: 1200px;
        margin: auto;
        
        transition: background 0.25s ease;
        
        @include breakpoint('tablet-wide') {
            padding: 20px 10px;
        }
    }
    
    #mainHeader--left {
        display: flex;
        align-items: center;
    }
    
    #mainHeader--right {
        @include breakpoint('tablet-wide', 'max') {
            display: flex;
            align-items: center;
            
            #mainNavigation--ticker {
                margin-right: 20px;
                font-size: 2.0rem;
            }
        }
    }
    
    #mainHeader--logo {
        width: 90px;
        transition: width 0.5s ease;
        
        @include breakpoint('tablet') {
            width: 120px;
        }
        
        @include breakpoint('tablet-wide') {
            width: 150px;
        }
        
        @include breakpoint('desktop') {
            width: 240px;
        }
        
        IMG {
            width: 100%;
        }
        
        &.animate {
            IMG {
                transition: width 0.5s ease;
            }
        }
    }
}

#mainHeader--navigation-toggle {
    font-size: 3.0rem;

    color: $pyxus-blue;
    
    .open {
        display: block;
    }
    
    .close {
        display: none;
        color: $pyxus-blue;
    }
}

#mainHeader--logo--pyxus--chevron {
    display: block;
    position: relative;
    max-width: 240px;
    overflow: hidden;
    
    IMG {
        position: relative;
        width: 100%;
    }
}
