/* ============================================================================================== */
/* !FOOTER */

#mainFooter {
    position: relative;
    
    //background: $pyxus-blue;
    color: #fff;
    font-size: 1.2rem;
    
    padding: 20px 0px;
    margin-top: 200px;
    
    @include breakpoint('tablet') {
        margin-top: 300px;
    }
    
    UL {
        font-size: 1.4rem;
    }
    
    A {
        color: #fff;
        transition: color 0.25s ease;
        
        &:hover {
            color: $pyxus-yellow;
        }
    }
    
    #mainFooter--logo {
        margin-right: 60px;
        margin-bottom: 30px;
        
        @include breakpoint($footer-breakpoint) {
            margin-bottom: 0;
            width: 100%;
            margin-bottom: 40px;
        }
        
        @include breakpoint('tablet-wide') {
            width: auto;
        }
        
        IMG {
            height: auto;
            max-width: 90px;
            
            @include breakpoint($footer-breakpoint) {
                max-width: 120px;
            }
            
            @include breakpoint('tablet-wide') {
                max-width: 260px;
            }
        }
    }
    
    #mainFooter--right {
        display: flex;
        align-items: stretch;
    }
    
    #mainFooter--container {
        @include breakpoint($footer-breakpoint) {
            display: flex;
            flex-wrap: wrap;
        }
    }
    
    #mainFooter--Navigation {
        @media(min-width: $tablet) and (max-width: $tablet-wide - 1) {
            max-width: 80%;
        }
        
        .nav-ul {
            display: flex;
            flex-wrap: wrap;
            
            LI {
                width: 50%;
                
                @include breakpoint($footer-breakpoint) {
                    width: 25%;
                }
            }
            
            LI {
                margin-bottom: 10px;
            }
        }
    }
    
    #mainFooter--social {
        margin-top: 50px;
        font-size: 1.4rem;
        
        
        
        @include breakpoint($footer-breakpoint) {
            width: 100%;
            margin-top: 20px;
        }
        
        @include breakpoint('tablet-wide') {
            width: auto;
            margin-top: 0;
        }
        
        .nav-ul {
            font-size: 3.2rem;
            display: flex;
            justify-content: space-between;
            
            LI {
                margin-right: 20px;
                
                &:last-child {
                    margin-right: 0;
                }
            }
            
            @include breakpoint('phone-wide') {
                width: auto;
                justify-content: flex-start;
                
                LI {
                    margin-right: 60px;
                    
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
            
            @include breakpoint('tablet-medium') {
                font-size: 2.0rem;
                
                LI {
                    margin-right: 20px;
                    
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
            
            
        }
    }
    
    #mainFooter--bottomNavigation {
        .nav-ul {
            
            display: flex;
            flex-wrap: wrap;
            // justify-content: space-between;
            
            LI {
                width: 50%;
                margin-bottom: 10px;
                
                @include breakpoint('phablet') {
                    width: 50%;
                }
                
                @include breakpoint('tablet-wide') {
                    width: 33.333333333%;
                }
                
                @include breakpoint('desktop-small') {
                    width: 25%;
                }
            }
        }
    }
    
    #copyright {
        font-size: 1.3rem;
        
        P {
            font-size: 1.3rem;
        }
    }
}


#footer--bg--desktop {
    display: none;
    position: absolute;
    bottom: 0px;
    right: 0px;
    
    @include breakpoint('phablet') {
         display: block;
    }
}

#footer--bg--mobile {
    display: block;
    position: absolute;
    bottom: 0px;
    right: 0px;

    width: 100%;
    
    @include breakpoint('phablet') {
         display: none;
         
    }
}

#footer--bg {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    
    width: 100%;
    background: url('/images/common/footer--bg--mobile.png') no-repeat center top / cover;
    height: 100%;
    max-height: 730px;
    z-index: 1;
    
    @include breakpoint($footer-breakpoint) {
        background: url('/images/common/footer--bg--full.png') no-repeat center bottom;
        height: 1216px;
        max-height: none;
    }
}