/* ============================================================================================== */
/* !CAREERS */

/* page */
.main--careers {
    @include breakpoint('tablet') {
        overflow: hidden;
    }
}

/* main block */
.careers {
    & > div {
        position: relative;
        z-index: 200;
    }
}

/* full width sections */
.careers__full-width:not(.careers__opportunities) {
    border-bottom: 1px solid $grey;
    padding-bottom: 40px;
}

/* guiding star video */
.careers__video-container {
    @include breakpoint('tablet') {
        display: flex;
        gap: 40px;
        align-items: center;
    }

    video {
        aspect-ratio: 16 / 9;
        object-fit: cover;
        margin-bottom: 20px;

        @include breakpoint('tablet') {
            width: 50%;
            margin: 0;
        }
    }
}

/* 2 column sections */
.careers__side-by-side {
    .blurb--padding {
        padding: 0 20px;
      
        @include breakpoint('tablet') {
            padding: 0 40px;
        }
    }

    .item {
        @include breakpoint('tablet') {
            padding: 0 20px;
        }
    }

    .image {
        @include breakpoint('tablet') {
            min-width: 170px;
            width: 17% !important;
            padding-right: 40px !important;
        }
    }
}

/* opportunity list */
.careers__opportunities-list {
    list-style: disc inside;
    
    @include breakpoint('tablet') {
        columns: 2;
    }

    li {
        margin-bottom: 15px;
    }
}

/* black line on 2 col sections */
.careers .feature--image-and-copy .item:not(:last-child) {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 25px;
}

/* white overlay to hide the bg image */
.careers__white-bg {
    display: none;
    background: white;
    height: calc(100% + 40px);
    width: 100%;
    position: absolute !important;
    z-index: 100 !important;
    top: 0;

    @include breakpoint('tablet') {
        display: block;
        margin: 0 100px;
    }
}
