/* ============================================================================================== */
/* !GLOBAL */

HTML {
    font-size: 62.5%;
}

BODY {
    font-family: $font-family--default;
    font-size: $font-size--default;
    color: $grey;
    background: #444;
    
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

P {
    font-size: $font-size--default;
}

UL {
    font-size: $font-size--default;
    
    
    &.ul--default {
        list-style: disc;
        margin-left: 22px;
        line-height: 1.25;
        
        LI {
            margin: 0px 0px 10px 0px;
            
            &:last-child {
                margin-bottom: 0;
            }
        }
        
        .ul--default {
            list-style: circle;
            margin-top: 10px;
            margin-bottom: 20px;
        }
    }
}

IMG {
    height: auto;
    width: 100%;
    
    &.normal {
        width: auto;
        max-width: 100%;
    }
}

HR {
    border: none;
    background: $grey--xl;
    height: 1px;
}

A {
    color: $pyxus-blue;
    transition: $transition--hover;
    text-decoration: none;
    
    &:hover {
        color: $pyxus-cyan;
    }
}

STRONG {
    font-weight: bold;
}

BUTTON {
    outline: none;
}

VIDEO { 
    width:100%;
    max-width: 1200px;
    height:auto;
}

SUP {
    position: relative;
    font-size: 50%;
    top: -0.5em;
    line-height: 0;
    vertical-align: baseline;
}

.nowrap {
    white-space: nowrap !important;
}

.border-top--none {
    border-top: none !important;
}

.columns--2 {
    
    .col {
        &:first-child {
            margin-bottom: 10px;
        }
    }
    
    @include breakpoint('tablet') {
        > .row {
            display: flex;
            justify-content: flex-start;
            
            > .col {
                width: 50%;
                
                &:first-child {
                    margin-bottom: 0px;
                }
            }
        }
    }
}


.video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
    
    IFRAME {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    } 
}

.border--none {
    border: 0 !important;
}

.rounded {
    -moz-border-radius: 	10px;
    -webkit-border-radius: 	10px;
    border-radius: 			10px;
    -khtml-border-radius: 	10px;
    overflow: hidden;
}

#container {
    position: relative;
    
    max-width: $site--max-width;
    margin: auto;
    padding-top: 200px;
    
    &:after {
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        width: 100%;
        height: 100%;
        z-index: -1;
        
        background-repeat: no-repeat;
        background-position: center top;
        background-color: #fff;
    }
    
/*
    &:after {
        content: "";
        background: url(../images/home/header--bg.jpg) no-repeat center top #fff;
        background-position: center -300px;
        position: absolute;
        top: 0px;
        right: 0px;
        width: 100%;
        height: 100%;
        z-index: -1;
        
        @include breakpoint('tablet-wide') {
            background-position: right top;
        }
    }
*/
}

.contentBlock {
    position: relative;
    z-index: 10;
    max-width: 1200px;
    margin: auto;
    padding: 0px 10px;
    
    @include breakpoint('tablet') {
        padding: 0px 20px;
    }
}

.contentBlock--wide {
    position: relative;
    z-index: 10;
    
    .inner {
        max-width: 1200px;
        margin: auto;
        padding: 0px 10px;
        
        @include breakpoint('tablet') {
            padding: 0px 20px;
        }
    }
}

.contentBlock--edge {
    position: relative;
    z-index: 10;
    
    &.with-shadow {
        -webkit-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.25);
        -moz-box-shadow:    0px 0px 30px 0px rgba(0,0,0,0.25);
        box-shadow:         0px 0px 30px 0px rgba(0,0,0,0.25);
        background: #fff;
    }

    .inner {
        max-width: 1200px;
        margin: auto;
        
        padding: 0px 10px;
        
        @include breakpoint('tablet') {
            padding: 0px 20px;
        }
    }
    
    &.panel-blue {
        background: $pyxus-blue;
        color: #fff;
    }
    
    &.panel-red {
        background: $pyxus-red;
        color: #fff;
    }
    
    &.contentBlock--indent {
        .inner {
            max-width: 960px;
        }
    }
}

.bg--light-grey {
    background: $grey--xxxl;
}

.text-center {
    text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.display--flex {
    display: flex;
}

.flex-grow--1 {
    flex-grow: 1;
}

.flex-shrink--0 {
    flex-shrink: 0;
}

.justify-content--center {
    justify-content: center;
}

.back-to-top {
    display: inline-block;
    
    &:before {
        content: "";
        display: block;
        background: url('../images/common/back-to-top.png') no-repeat center top;
        height: 39px;
        width: 64px;
        margin: 0px auto 20px auto;
    }
    
    color: $grey;
    text-decoration: none;
}


.page--header {
    > .inner {
        max-width: 1200px;
        margin: 0 auto;
        
    }
    
    .page--intro {
        color: #fff;
        
        P {
            width: 50%;
        }
    }
}

.page--intro {
    display: none;
    text-align: center;
    color: $pyxus-blue;
    font-size: 2.4rem;
    line-height: 1.5;
    font-weight: 600;
    padding: 0px 0px;
    
    P {
        font-size: 2.4rem;
    }
}

.relative {
    position: relative;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
