/* ============================================================================================== */
/* !BUTTONS */

.button {
    display: inline-block;
    cursor: pointer;
/*
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

*/
    border: 2px solid $pyxus-blue;
    -moz-border-radius: 	10px;
    -webkit-border-radius: 	10px;
    border-radius: 			10px;
    -khtml-border-radius: 	10px;

    text-decoration: none;
    padding: 10px 20px 8px 20px;

    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    background: $pyxus-blue;
    color: #fff !important;

    transition: border-color 0.25s ease, background 0.25s ease;
    white-space:normal;
    
    &:hover {
        background: $pyxus-cyan;
        border-color: $pyxus-cyan;
    }

    &.pyxus-red {
        border-color: $pyxus-red;
        background: $pyxus-red;

        &:hover {
            border-color: $grey;
            background: $grey;
        }
    }
}



.button-outline {
    display: inline-block;
    cursor: pointer;
/*
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

*/
    border: 2px solid $pyxus-blue;
    -moz-border-radius: 	10px;
    -webkit-border-radius: 	10px;
    border-radius: 			10px;
    -khtml-border-radius: 	10px;

    text-decoration: none;
    padding: 10px 20px 8px 20px;

    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1;
    color: $pyxus-blue;
    text-align: center;
    background: transparent;

    transition: color 0.25s ease, background 0.25s ease, border-color 0.25s ease;
    white-space:normal;

    &:hover {
        background: $pyxus-blue;
        border-color: $pyxus-blue;
        color: #fff;
    }

    &.active {
        background: $pyxus-blue;
        color: #fff;
    }


    &.white {
        border-color: #fff;
        color: #fff;

        &.active {
            background: #fff;
            color: $pyxus-red;
        }

        &:hover {
            background: #fff;
            color: $pyxus-red;
        }
    }

    &.pyxus-blue {
        border-color: $pyxus-blue;
        color: $pyxus-blue;

        &.active {
            background: $pyxus-blue;
            color: #fff;
        }

        &:hover {
            background: $pyxus-blue;
            color: #fff;
            border-color: $pyxus-blue;
        }
    }
}

.slick-current {
    position: relative;

    &:after {
        content: "";
        position: absolute;
        bottom: -25px;
        left: 50%;

        border-left: 30px solid transparent;
        border-right: 30px solid transparent;

        border-bottom: 30px solid #fff;
        transform: translateX(-50%);

        z-index: 999;
    }

    .button-outline {
        background: #fff;
        color: $pyxus-red;
    }
}

#slider--controller {
    padding: 0px 30px;

    @include breakpoint('tablet') {
        padding: 0px 20px;
    }

    @include breakpoint('tablet-wide') {
        padding: 0px 15%;
    }

    .slick-slide {
        position: relative;

        &:after {
            content: "";
            width: 0;
            height: 30px;
/*
            border-left: 30px solid transparent;
            border-right: 30px solid transparent;

            border-bottom: 30px solid #fff;
            transform: translateX(-50%);
*/
        }
    }

    .owl-carousel--tab {
        @include breakpoint('tablet') {
            font-size: 1.3rem;
        }

        @include breakpoint('tablet-wide') {
            font-size: 1.6rem;
        }
    }
}

.slick-slide {
    .button-outline {
        width: 90%;
    }
}



.buttons-container--default {
    padding: 0px 45px;

    @include breakpoint('tablet') {
        padding: 0;
    }

    .nav-ul {
        @include breakpoint('tablet') {
            display: flex;
            justify-content: center;
            padding: 0;

            LI {
                margin-right: 20px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }


    LI {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }

        @include breakpoint('tablet') {
            margin-bottom: 0;
        }
    }

    [class^='button'] {
        display: block;

        @include breakpoint('tablet') {
            display: inline-block;
        }
    }
}

.btn-filter-wrap {
    .owl-carousel--tab {
        margin: 0px 10px 10px 0px;
    }

    @include breakpoint('tablet') {
        .btn-filter, .owl-carousel--tab {
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.home-brands-container .btn-filter-wrap {

    a {
        border-color: white;
        color: white;
    }

    a.active,
    a:hover {
        background-color: white;
        border-color: white;
        color: $pyxus-red;
    }
}