/* ============================================================================================== */
/* !BREAKPOINTS */

.hidden--lt-tablet-medium {
    @include breakpoint('tablet-medium', 'max') {
        display: none !important;
    }
}

.hidden--lt-tablet {
    @include breakpoint('tablet', 'max') {
        display: none !important;
    }
}

.hidden--lt--phablet {
    @include breakpoint('phablet', 'max') {
        display: none !important;
    }
}

.hidden--lt--phone-wide {
    @include breakpoint('phone-wide', 'max') {
        display: none !important;
    }
}





.hidden--gt-tablet {
    @include breakpoint('tablet') {
        display: none !important;
    }
}

.hidden--gt-phablet {
    @include breakpoint('phablet') {
        display: none !important;
    }
}

.hidden--gt--phone-wide {
    @include breakpoint('phone-wide') {
        display: none !important;
    }
}