/* ============================================================================================== */
/* !TYPOGRAPHY */



/* >> Name of the FA free font (mandatory)
               - 'Font Awesome 5 Free' for Regular and Solid symbols;
               - 'Font Awesome 5 Brand' for Brands symbols.
    font-family: 'Font Awesome 5 Free';
    /* >> Weight of the font (mandatory)
               - 400 for Regular and Brands symbols;
               - 900 for Solid symbols.
    font-weight: 900;
 */
    
.h1 {
    font-size: 3.0rem;
}

.h2 {
    font-size: 2.8rem;
}

.h3 {
    font-size: 2.4rem;
}

.h4 {
    font-size: 2.0rem;
}

.h5 {
    font-size: 1.4rem;
}

.h6 {
    font-size: 1.2rem;
}

.lead {
    font-size: 2.0rem;
    line-height: 1.5;
    font-weight: 600;
    letter-spacing: 1.5;
}




@include breakpoint('tablet') {
    .h1 {
        font-size: 8.0rem;
    }
    
    .h2 {
        font-size: 4.0rem;
    }
    
    .h3 {
        font-size: 3.0rem;
    }
    
    .h4 {
        font-size: 2.0rem;
    }
    
    .h5 {
        font-size: 1.6rem;
    }
    
    .h6 {
        font-size: 1.4rem;
    }
    
    .lead {
        font-size: 2.4rem;
    }
}


    /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
	/* !FONT WEIGHTS */
	
	    .font-weight--700 {
    	    font-weight: 700 !important;
	    }
	    
	    .font-weight--600 {
    	    font-weight: 600 !important;
	    }
	    
	    .font-weight--500 {
    	    font-weight: 500 !important;
	    }
	    
	    .font-weight--400 {
    	    font-weight: 400 !important;
	    }
	    
	    .font-weight--300 {
    	    font-weight: 300 !important;
	    }
	    

P {
    margin-bottom: 20px;
    line-height: 1.25;
    
    &:last-child {
        margin-bottom: 0;
    }
}
