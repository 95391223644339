/* ============================================================================================== */
/* !ACCORDIONS */

.close {
    cursor: pointer;
}
    
.feature--accordion--default {
    .control {
        position: relative;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        
        display: flex;
        align-items: center;
        justify-content: center;
        height: 250px;
        cursor: pointer;
        transition: transform 0.5s ease;
        
        &:before {
            content: "";
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            background: rgba(0, 0, 0, 0.25);
            transition: background 0.25s ease;
        }
        
        &:hover {
            transform: scale(1.025);
            
            &:before {
                background: rgba(0, 0, 0, 0);
            }
        }
        
        &.active {
            transform: scale(1.025);
            
            &:before {
                background: rgba(13, 124, 191, 0.5);
            }
        }
        
        .title {
            font-size: 2.8rem;
            color: #fff;
            text-align: center;
            z-index: 1;
            
            &:after {
                content: "\f107";
                font-family: $font-awesome;
                font-weight: 900;
                margin-left: 10px;
                display: inline-block; 
                
                position: relative;
                top: 3px;
            }
        }
    }
    
    .expandable {
        display: none;
        background: $pyxus-blue;
        color: #fff;
        padding: 40px;
        
        > .inner {
            max-width: 1000px;
            margin: auto;
        }
    }
    
    .close {
        cursor: pointer;
        font-size: 2.0rem;
        
        @include breakpoint('tablet') {
            font-size: 3.0rem;
        }
    }
}


.table--info {
    border: 1px solid rgba(255, 255, 255, 0.5);
    
    @include breakpoint('tablet') {
        display: flex;
        border: 0;
    }
    
    > DIV {
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        
        &:last-child {
            border: 0;
        }
        
        @include breakpoint('tablet') {
            border: 0;
            
            &.column--1 {
                width: 40%;
            }
            
            &.column--2 {
                width: 20%;
                text-align: center;
                border-left: 1px solid rgba(255, 255, 255, 0.5);
                border-right: 1px solid rgba(255, 255, 255, 0.5);
            }
            
            &.column-33 {
                width: 20%;
            }
        }
    }
    
    .table--info--status {
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        @include breakpoint('tablet') {
            display: block;
        }
        
        .title {
            flex-grow: 1;
            border-right: 1px solid rgba(255, 255, 255, 0.5);
            border-bottom: 0;
            
            @include breakpoint('tablet') {
                border-right: 0;
                border-bottom: 1px solid rgba(255, 255, 255, 0.5);
            }
        }
        
        .icon--status {
            padding: 0px 20px;
            
            @include breakpoint('tablet') {
                padding-top: 20px;
            }
        }
    }
    
    .title {
        padding: 20px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        font-weight: 600;
    }
    
    P {
        padding: 20px;
    }
    
    .column--1 {
         @include breakpoint('tablet') {
            .title {
                padding-left: 0;
            }
            
            P {
                @include breakpoint('tablet') {
                    padding-left: 0;
                }
            }
        }
    }
    
    .column--3 {
         @include breakpoint('tablet') {
            .title {
                padding-right: 0;
            }
            
            P {
                @include breakpoint('tablet') {
                    padding-right: 0;
                }
            }
        }
    }
}

.accordion--text {
    .item {
        border-bottom: 1px solid $grey--xl;
    }
    
    .control {
        display: flex;
        justify-content: space-between;
        
        cursor: pointer;
        color: $pyxus-blue;
        font-size: 1.6rem;
        font-weight: 600;
        padding: 20px 20px 20px 0px;
        
        @include breakpoint('tablet') {
            font-size: 2.4rem;
        }
        
        &:after {
            content: "\f105";
            font-family: $font-awesome;
            font-weight: 900;
            transition: transform 0.25s ease-out;
        }
        
        &.active {
            &:after {
                transform: rotate(90deg);
            }
        }
    }
    
    .expandable {
        display: none;
        padding: 0px 40px 20px 40px;
        line-height: 1.25;
    }
}



#executive-grid {
    .item {
        transition: padding-bottom 0.5s ease;
    }
    
    .expandable {
        position: absolute;
        left: -10px;
        right: -10px;
        
        @include breakpoint('tablet-small') {
            left: 0px;
            right: 0px;
        }
        
        @include breakpoint('desktop-small') {
            left: -20px;
            right: -20px;
        }
        
        @include breakpoint('desktop') {
            left: -80px;
            right: -80px;
        }
        
        .item {
            position: relative;
            max-width: 1200px;
            margin: 0 auto;
        }
    }
    
    .close {
        font-size: 2.0rem;
        
        @include breakpoint('tablet') {
            font-size: 4.0rem;
        }
        
            
        &:hover {
            color: $grey--xxxl;
        }
    }
}


.card--image-text {
    @include breakpoint('tablet') {
        display: flex;
        justify-content: space-between;
        
        > .left {
            width: 30%;
        }
        
        > .right {
            width: 70%;
            padding-left: 80px;
            padding-right: 80px;
        }
    }
    
    .header {
        color: #fff;
        margin-bottom: 20px;
        font-size: 2.0rem;
        
        P {
            font-size: 2.0rem;
        }
        
        @include breakpoint('tablet') {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            //padding-right: 40px;
            
            > .left {
                max-width: 75%;
            }
            
            > .right {
                padding-left: 30px;
                flex-shrink: 0;
            }
        }
        
        A {
            color: #fff;
            
            &:hover {
                color: $grey--xxxl;
            }
        }
        
        .social {
            .nav-ul {
                font-size: 2.4rem;
            }
        }
        
        .nav-ul {
            display: flex;
            align-items: center;
            
            LI {
                margin-right: 15px;
                
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
    
    .body {
        padding-right: 40px;
    }
    
    .image {
        @include breakpoint('tablet', 'max') {
            margin-bottom: 20px;
            width: 50%;
        }
    }
}