/* ============================================================================================== */
/* !NAVIGATION */

NAV {
    A {
        text-decoration: none;
    }
}

#mainHeader--logo--desktop {
    display: none;
}

#mainHeader--mobile--ticker {
    display: none;
}

#mainHeader--navigation-toggle {
    z-index: 999;
    position: relative;
    
    .close {
        display: none;
    }
    
    @include breakpoint('tablet-wide') {
        display: none;
    }
}

#mainHeader--navigation-close {
    cursor: pointer;
    display: none;
    font-size: 3.0rem;
    color: $pyxus-blue;
    text-align: right;
    padding: 20px;
}

#topNavigation {
    display: none;
    
    @include breakpoint('tablet-wide') {
        display: block;
        margin-bottom: 20px;
    }
    
    .nav-ul {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        
        LI {
            &:after {
                content: "|";
                padding: 0px 10px;
            }
            
            &:last-child {
                &:after {
                    display: none;
                }
            }
        }
    }
    
    A {
        &:hover {
            //text-decoration: underline;
        }
        
        
        &.active {
            @include breakpoint('tablet-wide') {
                font-weight: bold;
            }
        }
    }
    
    
    A  {
        color: #fff !important;
        border: 2px solid transparent;
        -moz-border-radius: 	10px;
            -webkit-border-radius: 	10px;
            border-radius: 			10px;
            -khtml-border-radius: 	10px;
            
        &:hover {
            border-color: #fff;
        }
        
        &.active {
            background: #fff;
            color: $pyxus-blue !important;
            font-weight: 600;
            
            transition: color 0.25s ease;
            line-height: normal;
            border-color: #fff;
        }
        
        padding: 20px 20px 20px 40px;
        
        @include breakpoint('tablet-wide') {
            padding: 5px 10px 3px 10px;
        }
    }
}

.subNavigation {
    display: none;
    line-height: 1;
    
    A {
        &.active {
            font-weight: 700;
            text-decoration: underline;
            
            &:hover {
                color: $pyxus-blue !important;
            }
        }
    }
    
    @include breakpoint('tablet-wide') {
        position: absolute;
        top: 34px;
        left: 0px;
        position: absolute;
        padding: 0px 10px;
        margin-top: 10px;
        background: $grey--xxxl;
        min-width: 220px;
        
        -moz-border-radius: 	10px;
        -webkit-border-radius: 	10px;
        border-radius: 			10px;
        -khtml-border-radius: 	10px;
        
        LI {
            &:first-child {
                padding-top: 5px;
            }
            
            &:last-child {
                padding-bottom: 5px;
            }
        }
    }
}

#mainNavigation {
    display: none;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    overflow-y: auto;
    z-index: 999;
    
    
    background: $grey--xxxl;
    color: $pyxus-blue;
    
    A {
        &.level--0 {
            &.open {
                @include breakpoint('tablet-wide', 'max') {
                    + .subNavigation {
                        LI {
                            &:first-child {
                                padding-top: 0px;
                                
                                A {
                                    padding-top: 0;
                                }
                            }
                            
                            &:last-child {
                                padding-bottom: 10px;
                            }
                        }
                    }
                }
                
                &.active {
                    + .subNavigation {
                        LI {
                            &:first-child {
                                @include breakpoint('tablet-wide', 'max') {
                                    padding-top: 10px;
                                }
                                
                                A {
                                    padding-top: 10px;
                                }
                            }
                        }
                    }
                }
            }
            
            &.active {
                background: $pyxus-blue;
                color: #fff;
                
                &:hover {
                    color: #fff;
                }
                
                @include breakpoint('tablet-wide', 'max') {
                    + .subNavigation {
                        LI {
                            &:first-child {
                                padding-top: 10px;
                            }
                            
                            &:last-child {
                                padding-bottom: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
    
    @include breakpoint('tablet-wide') {
        position: relative;
        //display: block !important;
        background: transparent;
        color: #fff;
        overflow: visible;
        
        A {
            &.level--0 {
                color: #fff !important;
                border: 2px solid transparent;
                -moz-border-radius: 	10px;
                    -webkit-border-radius: 	10px;
                    border-radius: 			10px;
                    -khtml-border-radius: 	10px;
                    
                &:hover {
                    border-color: #fff;
                }
                
                &.active {
                    background: #fff;
                    color: $pyxus-blue !important;
                    font-weight: 600;
                    
                    transition: color 0.25s ease;
                    line-height: normal;
                    border-color: #fff;
                }
            }
        }
        
        .nav-ul {
            display: flex;
            align-items: center;
        }
    }
    
    .nav-ul {
        line-height: normal;
        
        > LI {
            position: relative;
            border-bottom: 1px solid $grey--light;
            
            @include breakpoint('tablet-wide') {
                border: 0;
                margin-right: 15px;
                
                &:last-child {
                    margin-right: 0;
                }
            }
            
            LI {
                position: relative;
                
                A {
                    display: inline-block;
                    padding: 10px 0px 10px 60px;
                    
                    @include breakpoint('tablet-wide') {
                        padding: 5px 10px 3px 10px;
                    }
                    
                    &:hover {
                        color: $pyxus-blue;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    
    #mainNavigation--ul {
        @include breakpoint('tablet-wide', 'max') {
            LI {
                &:last-child {
                    border: 0;
                }
            }
        }
    }
    
    A {
        color: $pyxus-blue;
        display: flex;
        justify-content: space-between;
        padding: 20px 20px 20px 40px;
        
        @include breakpoint('tablet-wide') {
            padding: 5px 10px 3px 10px;
        }
        
        &:hover {
            color: $pyxus-cyan;
        }
        
        &.hasSub {
            &:after {
                transition: transform 0.25s ease;
                
                content: "\f105";
                display: inline-block;
                margin-left: 5px;
                font-family: $font-awesome;
                font-size: 2.4rem;
                font-weight: 900;
                
                
                @include breakpoint('tablet-wide') {
                    content: "\f0d7";
                    font-size: 1.6rem;
                }  
            }
        }
    }
    
    .level--0 {
        font-weight: 600;
        
        &.open {
            &:after {
                transform: rotate(90deg);
            }
        }
        
        @include breakpoint('tablet-wide') {
            display: inline-block;
            
            &.open {
                &:after {
                    transform: rotate(180deg);
                }
            }
        }
        
    }
    
    #mainNavigation--ticker {
        display: inline-block;
        
        @include breakpoint('tablet-wide') {
            display: inline-block;
            padding-top: 5px;
            padding-bottom: 3px;
        }
    }
}


.secondaryNavigation--top {
    border-top: 1px solid $grey--xl;
    padding-top: 20px;
    
    @include breakpoint('tablet-wide') {
        border: 0;
        padding-top: 0;
    }
    
    .nav-ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: stretch;
        
        padding: 0px 40px;
        
        @include breakpoint('tablet-medium') {
            flex-wrap: nowrap;
        }
        
        LI {
            width: 50%;
            margin-bottom: 10px;
            padding: 4px 8px;
            
            @include breakpoint('tablet') {
                width: auto;
            }
            
            @include breakpoint('tablet-medium') {
                margin-right: 12px;
                width: auto;
                
                &:last-child {
                    margin-right: 0;
                }
                
                &:after {
                    display: none;
                }
            }
        }
        
        A {
            @extend .button-outline;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            
            @include breakpoint('tablet-wide') {
                min-width: 150px;
            }
        }
    }
    
    &.columns--3 {
        .nav-ul {
            @include breakpoint('tablet-medium') {
                flex-wrap: wrap;
                //margin: 0px -10px;
            }
        
            LI {
                margin-right: 0;
                
                @include breakpoint('tablet') {
                    width: 33.3333333333%;
                }
            
                @include breakpoint('tablet-medium') {
                    width: 33.3333333333%;
                }
            }
        }
    }
}



.asideNavigation {
    font-weight: 600;
    margin-bottom: 40px;
    
    > UL {
        > LI {
            > A {
                @extend .rounded;
                display: block;
                padding: 16px 20px 10px 20px;
                
                &.active {
                    background: $pyxus-blue;
                    color: #fff;
                }
            }
        }
    }
    
    LI {
        padding: 0;
        
        &.hasSub {
            > A {
                -moz-border-radius: 	10px 10px 0px 0px;
                -webkit-border-radius: 	10px 10px 0px 0px;
                border-radius: 			10px 10px 0px 0px;
                -khtml-border-radius: 	10px 10px 0px 0px;
            }
            
            .nav-ul {
                padding: 10px 20px 10px 20px;
                background: $grey--xxxl;
                
                -moz-border-radius: 	0px 0px 10px 10px;
                -webkit-border-radius: 	0px 0px 10px 10px;
                border-radius: 			0px 0px 10px 10px;
                -khtml-border-radius: 	0px 0px 10px 10px;
                
                A {
                    display: inline-block;
                    padding: 16px 20px 10px 20px;
                    font-weight: 400;
                    
                    &.active {
                        //text-decoration: underline;
                        font-weight: 700;
                    }
                }
            }
        }
    }
    
    
}
