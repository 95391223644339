/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
	-webkit-animation-duration:1000ms;
	animation-duration:1000ms;
	-webkit-animation-fill-mode:both;
	animation-fill-mode:both;
}

.owl-carousel .owl-animated-in {
	z-index:0;
}

.owl-carousel .owl-animated-out {
	z-index:1;
}

.owl-carousel .fadeOut {
	-webkit-animation-name:fadeOut;
	animation-name:fadeOut;
}

@-webkit-keyframes fadeOut {
	0% {
		opacity:1;
	}

	100% {
		opacity:0;
	}
}

@keyframes fadeOut {
	0% {
		opacity:1;
	}

	100% {
		opacity:0;
	}
}

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
	-webkit-transition:height 500ms ease-in-out;
	-moz-transition:height 500ms ease-in-out;
	-ms-transition:height 500ms ease-in-out;
	-o-transition:height 500ms ease-in-out;
	transition:height 500ms ease-in-out;
}

/*
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
	display:none;
	width:100%;
	-webkit-tap-highlight-color:transparent;
/* position relative and z-index fix webkit rendering fonts issue */
	position:relative;
	z-index:1;
}

.owl-carousel .owl-stage {
	position:relative;
	-ms-touch-action:pan-Y;
}

.owl-carousel .owl-stage:after {
	content:".";
	display:block;
	clear:both;
	visibility:hidden;
	line-height:0;
	height:0;
}

.owl-carousel .owl-stage-outer {
	position:relative;
	overflow:hidden;
/* fix for flashing background */
	-webkit-transform:translate3d(0px,0px,0px);
}

.owl-carousel .owl-controls .owl-nav .owl-prev,.owl-carousel .owl-controls .owl-nav .owl-next,.owl-carousel .owl-controls .owl-dot {
	cursor:pointer;
	cursor:hand;
	-webkit-user-select:none;
	-khtml-user-select:none;
	-moz-user-select:none;
	-ms-user-select:none;
	user-select:none;
}

.owl-carousel.owl-loaded {
	display:block;
}

.owl-carousel.owl-loading {
	opacity:0;
	display:block;
}

.owl-carousel.owl-hidden {
	opacity:0;
}

.owl-carousel .owl-refresh .owl-item {
	display:none;
}

.owl-carousel .owl-item {
	position:relative;
	min-height:1px;
	float:left;
	-webkit-backface-visibility:hidden;
	-webkit-tap-highlight-color:transparent;
	-webkit-touch-callout:none;
	-webkit-user-select:none;
	-moz-user-select:none;
	-ms-user-select:none;
	user-select:none;
}

.owl-carousel .owl-item img {
	display:block;
	width:100%;
	-webkit-transform-style:preserve-3d;
	margin: auto;

	@media(max-width: 599px) {
    	width: auto;
			max-width: 100%;
	}
}

.owl-carousel.owl-text-select-on .owl-item {
	-webkit-user-select:auto;
	-moz-user-select:auto;
	-ms-user-select:auto;
	user-select:auto;
}

.owl-carousel .owl-grab {
	cursor:move;
	cursor:-webkit-grab;
	cursor:-o-grab;
	cursor:-ms-grab;
	cursor:grab;
}

.owl-carousel.owl-rtl {
	direction:rtl;
}

.owl-carousel.owl-rtl .owl-item {
	float:right;
}

/* No Js */
.no-js .owl-carousel {
	display:block;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
	opacity:0;
	-webkit-transition:opacity 400ms ease;
	-moz-transition:opacity 400ms ease;
	-ms-transition:opacity 400ms ease;
	-o-transition:opacity 400ms ease;
	transition:opacity 400ms ease;
}

.owl-carousel .owl-item img {
	transform-style:preserve-3d;
}

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
	position:relative;
	height:100%;
	background:#000;
}

.owl-carousel .owl-video-play-icon {
	position:absolute;
	height:80px;
	width:80px;
	left:50%;
	top:50%;
	margin-left:-40px;
	margin-top:-40px;
	background:url(owl.video.play.png) no-repeat;
	cursor:pointer;
	z-index:1;
	-webkit-backface-visibility:hidden;
	-webkit-transition:scale 100ms ease;
	-moz-transition:scale 100ms ease;
	-ms-transition:scale 100ms ease;
	-o-transition:scale 100ms ease;
	transition:scale 100ms ease;
}

.owl-carousel .owl-video-play-icon:hover {
	-webkit-transition:scale(1.3,1.3);
	-moz-transition:scale(1.3,1.3);
	-ms-transition:scale(1.3,1.3);
	-o-transition:scale(1.3,1.3);
	transition:scale(1.3,1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,.owl-carousel .owl-video-playing .owl-video-play-icon {
	display:none;
}

.owl-carousel .owl-video-tn {
	opacity:0;
	height:100%;
	background-position:center center;
	background-repeat:no-repeat;
	-webkit-background-size:contain;
	-moz-background-size:contain;
	-o-background-size:contain;
	background-size:contain;
	-webkit-transition:opacity 400ms ease;
	-moz-transition:opacity 400ms ease;
	-ms-transition:opacity 400ms ease;
	-o-transition:opacity 400ms ease;
	transition:opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
	position:relative;
	z-index:1;
}




#demos {
    padding-top: 60px;
    padding-bottom: 60px;

    .owl-carousel {
        padding: 0px 80px;
    }
}



#demos .owl-carousel .item {
	//background:#4dc7a0 none repeat scroll 0 0;
	height:20rem;
	padding:1rem;

	display: flex;
	align-items: center;
}

#demos .owl-carousel .item {
	transition:.8s;
	-webkit-transition:.8s;
	transform:translateX(0);
	-webkit-transform:translateX(0);
	opacity:1;
}

#demos .owl-carousel .item.__loading {
	opacity:0;
	transform:translateX(40px);
	-webkit-transform:translateX(40px);
}

#demos .owl-carousel .item h4 {
	margin:0 0 10px;
}

.btn-filter-wrap {
	margin-bottom:10px;
}

.btn-filter-wrap .btn-filter {
    cursor: pointer;
/*
	color:#1C82CC;
	border:1px solid #1C82CC;
	background:#FFF;
	padding:4px 20px;
	margin-left:10px;
	margin-bottom:10px;
*/
}

.btn-filter.btn-active {
	background:#1C82CC;
	color:#FFF;
}

.site-title {
	margin-bottom:40px;
}

.__doc {
	margin-top:50px;
}

/*
footer {
	margin-top:140px;
	background:#333;
	color:#FFF;
	padding:10px 0;
	text-align:center;
}
*/


.owl-theme .owl-nav [class*='owl-'] {
    font-size: 5.4rem;
    color: $pyxus-cyan;
    background: none;
}

.owl-prev {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;

    //transform: translateY(100%);
    font-size: 5.4rem;
    color: $pyxus-cyan;

    display: flex !important;
    align-items: center;
    margin-top: -15px !important;
}

.owl-next {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;

    //transform: translateY(100%);
    font-size: 5.4rem;
    color: $pyxus-cyan;

    display: flex !important;
    align-items: center;
    margin-top: -15px !important;
}


.owl-theme .owl-dots .owl-dot SPAN {
    border: 2px solid #fff;
}



.owl-theme .owl-dots .owl-dot.active span {
    background: none;
    border-color: $pyxus-cyan;
}

.owl-theme .owl-dots .owl-dot:hover span {
    background: $pyxus-cyan;
    border-color: #fff;
}

#owl-carousel--tabs--controls {
    .owl-prev {
        cursor: pointer;
        color: #fff;

        &:hover {
            color: $grey--xxxl;
        }
    }

    .owl-next {
        cursor: pointer;
        color: #fff;

        &:hover {
            color: $grey--xxxl;
        }
    }
}

.owl-carousel--tab {
    cursor: pointer;
}

#owl-carousel--tabs {
    .owl-dots {
        display: none !important;
    }
}


.panel-red {
    .owl-theme .owl-dots .owl-dot:hover span {
        background: transparent;
        border-color: #fff;
    }

    .owl-theme .owl-dots .owl-dot.active span {
        background: #fff;
        border-color: #fff;
    }

    .owl-theme .owl-nav [class*='owl-'] {
        color: #fff;
    }

    .owl-theme .owl-dots .owl-dot span {
        background: transparent;
    }

    .owl-controls {
        padding-top: 40px;
    }
}


#owl-carousel--brands {
    max-width: 1200px;
    margin: auto;

    .sentri {
        max-width: 100px;
    }
}

.btn-filter-wrap .btn-filter, .btn-filter-wrap .owl-carousel--tab {
    white-space: nowrap;
}

.brands--filter-container {
    max-width: 1200px;
    margin: auto;
    text-align: center;

    display: flex;
    flex-wrap: wrap;

    > .btn-filter {
        width: 50%;
    }
}

/*
 * Owl Carousel - Banners (Home).
 */

 .home-our-impact {
  & .owl-controls {
      width: 100%;
  }

  & .owl-nav {
      @include breakpoint('tablet', max) {
          display: none !important;
      }

      & i {
          color: #fff;
          transition: all 0.3s ease-in;

          &:hover {
              color: $grey--light;
          }
      }
  }

  & .owl-item {
      &:nth-child(odd) .copy {
          background: $grey--xxxl;
      }

      & .image,
      & .image img {
          width: 100% !important;
      }

      & .blurb--text-grey {
          color: #606060 !important;
      }
  }
}