/* ============================================================================================== */
/* !IMPACT */


#impact--sortby {
    @include breakpoint('phablet', 'max') {
        .left {
            width: 100%;
        }
        
        .nav-ul {
            flex-wrap: wrap;
            justify-content: center;
            
            LI {
                &:first-child {
                    width: 100%;
                    margin-bottom: 10px;
                    text-align: center;
                    
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
}

#impact--tags {
    @include breakpoint('phablet', 'max') {
        .left {
            width: 100%;
        }
        
        .nav-ul {
            flex-wrap: wrap;
            
            LI {
                &:first-child {
                    width: 100%;
                    margin-bottom: 10px;
                    
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
}