/* ============================================================================================== */
/* !TRACEABILITY */

.section--sentri {
    #sentri-logo {
        max-width: 300px;
        
        @include breakpoint('tablet', 'max') {
            margin-top: 60px;
            max-width: 200px;
        }
    }
    
    #mainHeader {
        &.sticky {
            background: rgba(239, 99, 72, 0.95);
        }
    }
    
    #mainNavigation {
        A {
            color: $pyxus-red;
        }
    }
    
    #mainNavigation .nav-ul > LI LI A:hover {
        color: $grey;
    }
    
    #container {
        &:after {
            background-image: url('/images/sentri/sentri--bg--header--mobile.png');
            
            @include breakpoint('tablet') {
                background-image: url('/images/sentri/sentri--bg--header.png');
            }
        }
    }
    
    #footer--bg {
        background-image: url('/images/sentri/sentri--bg--footer--mobile.png');
        max-height: 1200px;
        
        @include breakpoint($footer-breakpoint) {
            background-image: url('/images/sentri/sentri--bg--footer.png');
            max-height: none;
        }
    }
    
    .page--intro {
        @include breakpoint('tablet', 'max') {
            color: $pyxus-red;
        }
    }
    
/*
    .pyxus-blue {
        color: $pyxus-red;
    }
*/
    
    .button {
        background: $pyxus-red;
        border-color: $pyxus-red;
        
        &:hover {
            background: $pyxus-yellow;
            border-color: $pyxus-yellow;
        }
    }
    
    .secondaryNavigation--top {
        .nav-ul {
            A {
                border-color: $pyxus-red;
                color: $pyxus-red;
                
                &:hover {
                    background: $pyxus-red;
                    color: #fff;
                }
            }
        }
    }
    
    .accordion--text {
        .control {
            color: $pyxus-red;
            
            &:hover {
                color: $grey;
            }
        }
    }
}

#sentri--evolution {
    position: relative;
    z-index: 9;
    
    &:after {
        content: "";
        position: absolute;
        top: -300px;
        left: 0px;
        right: 0px;
        width: 100%;
        height: 1653px;
        background: url(/images/common/bg--orange--right.png) no-repeat center top;
        z-index: -1;
    } 
}

.sentri--intro {
    padding: 0px 20px;
    
    .title {
        margin-bottom: 40px;
        
        @include breakpoint('tablet') {
            margin-bottom: 10px;
        }
    }
    
    .image {
        margin-bottom: 30px;
        
        IMG {
            max-width: 150px;
        }
    }
    
    @include breakpoint('tablet') {
        display: flex;
        text-align: left;
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
        padding: 0;
        
        .image {
            padding-right: 50px;
        }
    }
}


#sentri--footerPanel {
    max-width: 960px;
    margin: auto;
    
    .image {
        IMG {
            max-width: 150px;
        }
    }
}

#sentri--keyFeatures {
    position: relative;
    z-index: 9;
    
    &:after {
        content: "";
        position: absolute;
        top: -200px;
        left: 0px;
        right: 0px;
        width: 100%;
        height: 1653px;
        background: url(/images/sentri/sentri--bg--orange.png) no-repeat center top;
        z-index: -1;
    } 
}

#owl-carousel--quotes {
    .item {
        max-width: 800px;
        padding: 0px 60px;
        margin: auto;
        
    }
}