/* ============================================================================================== */
/* !BREADCRUMBS */

.breadcrumb--default {
    display: flex;
    justify-content: space-between;
    
    max-width: 1200px;
    margin: auto;
    
    border-bottom: 1px solid $grey--xl;
    padding-bottom: 20px;
    
    .nav-ul {
        display: flex;
        align-items: center;
        
        LI {
            &:after {
                content: "|";
                margin: 0px 10px;
            }
            
            &:last-child {
                &:after {
                    display: none;
                }
            }
        }
    }
    
    A {
        text-decoration: none;
        color: $pyxus-blue;
        font-weight: 600;
    }
    
    .center {
        text-align: center;
    }
}

.pagination--default {
    @extend .breadcrumb--default;
    
    .nav-ul {
        margin: auto;
    }
}