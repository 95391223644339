/* ============================================================================================== */
/* !FEATURES */


.feature {
    .title {
        font-size: 2.4rem;
        font-weight: 600;
        color: $grey;
    }

    .tag {
        font-weight: 600;
        margin-bottom: 20px;
    }

    .blurb {
        line-height: 1.5;
        color: $grey;
    }

    .cta {
        margin-top: 20px;

        A {
            font-weight: 600;
        }
    }

    .date {
        color: $grey;
        margin-top: 40px;
    }
}

.feature--title {
    @extend .h2, .pyxus-blue, .font-weight--600, .text-center;
    padding: 30px 20px 24px 20px;
}


/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* !FEATURE > GRID DEFAULT

    Standard 3-column Stacked Image+Copy Feature
*/

.feature--grid--default {
    @include breakpoint('tablet') {
        max-width: none;
    }

    .items-container {
        @include breakpoint('tablet') {
            display: flex;
            flex-wrap: wrap;
        }

        > .item {
            @include breakpoint('tablet') {
                width: 33.3333333333%;
            }

            &:nth-child(odd) {
                .copy {
                    background: $grey--xxxl;
                }
            }
        }
    }

    IMG {
        height: auto;
        width: 100%;
    }

    .lead {
        padding: 40px 40px 34px 40px;
        line-height: 1.5;
    }

    .copy {
        padding: 40px;
        //padding-right: 25%;
        height: 100%;

        @include breakpoint('tablet') {
            padding-right: 40px;
        }
    }

    .title {
        font-size: 2.4rem;
        font-weight: 600;
        color: $grey;
    }

    .tag {
        font-weight: 600;
        margin-bottom: 20px;
    }

    .blurb {
        line-height: 1.5;
        color: $grey;
    }

    .cta {
        margin-top: 20px;

        A {
            font-weight: 600;
        }
    }

    .date {
        color: $grey;
        margin-top: 40px;
    }
}



/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* !FEATURE > GRID TEXT

    Custom 3-column Grid which appears on Homepage
*/

.feature--grid--text {
    font-size: 2.0rem;
    padding: 40px 0px;

    .items-container {
        @include breakpoint('tablet') {
            display: flex;
            flex-wrap: wrap;
        }

        > .item {
            @include breakpoint('tablet') {
                width: 33.3333333333%;
            }
        }
    }

    .number {
        font-size: 5.4rem;
        font-weight: 600;
        /*
        background: -webkit-linear-gradient(left, $pyxus-blue, $pyxus-cyan);
        background: -o-linear-gradient(right, $pyxus-blue, $pyxus-cyan);
        background: -moz-linear-gradient(right, $pyxus-blue, $pyxus-cyan);
        background: linear-gradient(to right, $pyxus-blue, $pyxus-cyan);
        */
        -webkit-text-fill-color: transparent;
        background: -webkit-linear-gradient(transparent, transparent), url("../images/home/number_bg.jpg");
        background: -moz-linear-gradient(transparent, transparent), url("../images/home/number_bg.jpg");
        background: -o-linear-gradient(transparent, transparent), url("../images/home/number_bg.jpg");

        color: $pyxus-cyan;
        
        background-repeat: no-repeat;
        background-size: 100% auto;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @include breakpoint('tablet-wide') {
            font-size: 7.2rem;
        }
    }

    .blurb {
        font-size: 1.6rem;

        @include breakpoint('tablet-wide') {
            font-size: 2.4rem;
        }
    }
}



/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* !FEATURE > ROWS DEFAULT

    Feature with rows which have a smaller Image on the Left and larger Copy on the Right
*/

.feature--rows--default {
    @extend .feature--grid--default;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    -moz-border-radius: 	0px;
    -webkit-border-radius: 	0px;
    border-radius: 			0px;
    -khtml-border-radius: 	0px;


    .items-container {
        .item {
            @include breakpoint('tablet') {
                width: 100%;
                display: flex;
            }

            &:nth-child(odd) {
                background: $grey--xxxl;
            }

            > .image {
                @include breakpoint('tablet') {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    width: 20%;
                }
                text-align: center;
            }
        }

        .copy {
            text-align: center;

            @include breakpoint('tablet') {
                width: 80%;
                padding-left: 0;
                text-align: left;
            }
        }
    }

    .image {
        padding: 20px;
    }

    IMG {
        width: auto;
        max-width: 100%;
    }
    
    .items-container--text {
        padding: 15px;
        
        @include breakpoint('phablet') {
            padding: 20px;
        }
        
        @include breakpoint('tablet') {
            padding: 40px;
        }
    }
}







/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* !FEATURE > MEDIA GRID DEFAULT

    Feature with a Grid of Shadowed Items of 1, 2, 3, and 4 columns
*/

.media--grid--default {
    .items-container {
        position: relative;

        @include breakpoint('tablet-small') {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0px -10px;
            //margin: 0px -20px;
        }

        @include breakpoint('desktop') {
            margin: 0px -40px;
        }

        > .item {
            padding: 20px 0px;


            @include breakpoint('tablet-small') {
                width: 50%;
                padding: 20px;
            }

            @include breakpoint('tablet') {
                width: 33.3333333333%;
            }

            @include breakpoint('tablet-wide') {
                width: 25%;
            }

            .inner {
                @extend .panel--shadowed;
            }

            IMG {
                height: auto;
                width: 100%;
            }

            .copy {
                padding: 20px;
                text-align: center;
                color: $pyxus-blue;
                font-size: 1.6rem;

                @include breakpoint('tablet') {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }

                .name {
                    font-weight: bold;
                }

                .title {

                }
            }
        }

        // item on executive page
        .item--executive {
          @include breakpoint('tablet-wide') {
              width: 33% !important;
          }
      }
    }

    .linkable {
        cursor: pointer;

        .copy {
            transition: color 0.25s ease;
        }


        &:hover, &.active {
            .copy {
                background: $pyxus-blue;
                color: #fff;
            }
        }
    }

    .expandable {
        position: relative;
        display: none;
        background: #0d7cbf;
        color: #fff;
        padding: 40px 40px 40px 40px;
        margin-top: 20px;
    }

    .close-container {
        position: absolute;
        top: 0px;
        right: 0px;

        @include breakpoint('tablet-small') {
            top: -20px;
        }

        //padding: 10px 0px;
        text-align: right;
    }
}


/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* !FEATURE > GRID WITH 3-COLUMNS

    Feature with a Grid of Shadowed Items of 1, 2, and 3 columns
*/

.media--grid--columns--3 {
    @extend .media--grid--default;

    .items-container {

        @include breakpoint('tablet-small') {
            margin: 0px -20px;
            //margin: 0px -20px;
        }

        > .item {
            @include breakpoint('tablet-wide') {
                width: 33.3333333333%;
            }

            .copy {
                text-align: left;
            }
        }
    }
}


/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* !FEATURE > LARGE

    Feature with a Large Image and Copy below
*/


.feature--large {
    @extend .feature--grid--default;

    .items-container {
        @include breakpoint('tablet') {
            display: block;
            flex-wrap: nowrap;
        }

        > .item {
            @include breakpoint('tablet') {
                width: auto;
            }
        }
    }

    .copy {
        @include breakpoint('tablet') {
            display: flex;
            justify-content: space-between;

            .blurb {
                padding-right: 40px;
            }
        }
    }

    P {
        font-size: 2.0rem;
    }
}


/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* !FEATURE > GALLERY GRID

    Feature to display Logos and images in 1, 2, and 4 columns
*/

.gallery--grid--default {
    .items-container {
        @include breakpoint('tablet') {
            display: flex;
            flex-wrap: wrap;
            margin: 0px -20px;
        }

        @include breakpoint('desktop') {
            margin: 0px -40px;
        }

        > .item {
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: center;

            @include breakpoint('tablet') {
                width: 50%;
            }

            @include breakpoint('tablet-wide') {
                width: 25%;
            }

            @include breakpoint('desktop') {
                width: 25%;
            }

            IMG {
                height: auto;
                width: auto;
                max-width: 150px;
            }

            .copy {
                padding: 20px;
                text-align: center;
                color: $pyxus-blue;

                @include breakpoint('tablet') {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }

                .name {
                    font-weight: bold;
                }

                .title {

                }
            }
        }
    }
}



/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* !FEATURE > IMAGE AND COPY

    Custom Feature to display an Image on Left and Copy on Right
*/

.feature--image-and-copy {
    .item {
        border-bottom: 1px solid $grey;
        padding-bottom: 40px;
        margin-bottom: 40px;

        @include breakpoint('tablet') {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            &:nth-child(even) {
                .right {
                    order: 1;
                    text-align: right;
                    padding-left: 0;
                    padding-right: 30px;
                }

                .left {
                    order: 2;
                }
            }
        }

        .left {
            @include breakpoint('tablet') {
                width: 50%;
            }
        }

        .right {
            @include breakpoint('tablet') {
                padding-left: 30px;
                width: 50%;
            }
        }

        .copy {
            padding: 20px 40px;
            color: $grey;
            line-height: 1.5;

            @include breakpoint('tablet') {
                padding: 0px;
            }
        }

        .title {
            font-weight: 600;
            margin-bottom: 20px;
            line-height: 1.25;
        }

        .cta {
            margin-top: 20px;
        }

        .image {
            -moz-border-radius: 	10px;
            -webkit-border-radius: 	10px;
            border-radius: 			10px;
            -khtml-border-radius: 	10px;
            overflow: hidden;



            IMG {
                height: auto;
                width: 100%;
            }
        }
    }

    &.inverse {
        @include breakpoint('tablet') {
            .item {
                &:nth-child(even) {
                    .right {
                        order: 2;
                        text-align: left;
                    }

                    .left {
                        order: 1;
                        text-align: right;
                        padding-left: 0;
                        padding-right: 30px;
                    }
                }

                &:nth-child(odd) {
                    .right {
                        order: 1;
                        text-align: left;
                        padding-left: 0;
                        padding-right: 30px;
                    }

                    .left {
                        order: 2;
                    }
                }
            }
        }
    }

    &.with-tout {
        .item {
            border-bottom: 0;
            padding-bottom: 0px;
            margin-bottom: 0px;

            @include breakpoint('tablet') {
                align-items: stretch;
            }
        }

        .left {
            @include breakpoint('tablet') {
                width: 25%;
            }
        }

        .right {
            @include breakpoint('tablet') {
                padding-left: 30px;
                width: 75%;
            }

            @include breakpoint('tablet-wide') {
                padding-left: 100px;
            }
        }

        .header {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .title {
                width: 100%;
                text-align: center;

                @include breakpoint('tablet') {
                    width: auto;
                    text-align: left;
                }
            }
        }

        .info {
            .nav-ul {
                display: flex;

                LI {
                    &:after {
                        content: "|";
                        margin: 0px 8px;
                    }

                    &:last-child {
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }

        .copy {
            @include breakpoint('tablet', 'max') {
                padding: 20px;
            }
        }
    }
}


.content--aside {
    @include breakpoint('tablet') {
        display: flex;
        justify-content: space-between;

        .content {
            width: 70%;
            //padding-right: 30px;
        }

        .aside {
            width: 30%;
        }

        > *:first-child {
            padding-right: 30px;
        }
    }

    .content {
        .header {
            padding: 0px 40px;

            @include breakpoint('tablet') {
                padding: 0;
            }
        }

        .social {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            margin-bottom: 10px;

            .nav-ul {
                display: flex;
                flex-wrap: wrap;
                align-items: center;

                LI {
                    margin-bottom: 10px;

                    &:after {
                        content: "|";
                        margin: 0px 8px;
                        color: $grey;
                    }

                    &:last-child {
                        &:after {
                            display: none;
                        }
                    }

                    &.label {
                        font-weight: bold;

                        &:after {
                            display: none;
                        }
                        margin-right: 10px;
                    }
                }
            }

/*
            A {
                color: $pyxus-blue;
                transition: $transition--hover;
                text-decoration: none;

                &:hover {
                    color: $pyxus-yellow;
                }
            }
*/

            .left {
                order: 2;
                width: 100%;
            }

            .right {
                order: 1;
                width: 100%;
                margin-bottom: 30px;
            }

            @include breakpoint('tablet-small') {
                .left {
                    order: 1;
                    width: auto;
                }

                .right {
                    order: 2;
                    width: auto;
                    margin-bottom: 0px;
                }
            }
        }

        .tags {
            margin-bottom: 20px;

            .nav-ul {
                display: flex;
                flex-wrap: wrap;

                LI {
                    margin-bottom: 10px;

                    &:after {
                        content: "|";
                        margin: 0px 8px;
                        color: $grey;
                    }

                    &:last-child {
                        &:after {
                            display: none;
                        }
                    }

                    &.label {
                        &:after {
                            display: none;
                        }
                        margin-right: 10px;
                    }
                }
            }

            A {
                text-decoration: none;
                //color: $pyxus-blue;
                font-weight: 600;
            }
        }

        .title {
            font-size: 3.0rem;
            font-weight: 600;
            color: $grey;
            margin-bottom: 30px;
        }

        .lead {
            line-height: 1.25;
        }

        P {
            margin: 20px 0;
            //padding: 0px 40px;
            line-height: 1.25;

            @include breakpoint('tablet') {
                padding: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }

            &:first-child {
                margin-top: 0;
            }
        }
    }

    .aside {
        @include breakpoint('tablet', 'max') {
            > SECTION {
                margin-top: 30px;
            }
        }

        .title {
            font-size: 3.2rem;
            font-weight: 600;
            color: $pyxus-blue;
            border-bottom: 1px solid $grey;
            padding-bottom: 10px;
            margin-bottom: 10px;
        }

        .nav--text-with-borders {
            LI {
                border-bottom: 1px solid $grey;
                padding: 10px 0px;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            A {
                font-weight: 600;
            }
        }

/*
        A {
            text-decoration: none;
            color: $pyxus-blue;
            transition: $transition--hover;

            &:hover {
                color: $pyxus-yellow;
            }
        }
*/

        .aside--popular-articles {
            .title {
                margin-bottom: 20px;
            }

            .item {
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .image {
                //@extend .rounded;
            }

            .copy {
                padding: 20px 20px;

                @include breakpoint('tablet') {
                    //padding: 0;
                }
            }

            .blurb {
                padding: 0px 0px 0px 0px;
                color: $grey;
                line-height: 1.5;
            }
        }
    }
}


.feature--text-text {
    padding: 40px 0px;

    .copy {
        text-align: center;
        line-height: 1.5;

        .left {
            &:after {
                content: "";
                display: inline-block;
                width: 50%;
                height: 0;
                border-bottom: 1px solid #fff;
                padding-bottom: 20px;
                margin-bottom: 20px;
            }
        }

        @include breakpoint('tablet') {
            display: flex;
            justify-content: space-between;

            > .left,
            > .right {
                width: 50%;
                padding: 10px 20px;

                &:first-child {
                    border-right: 1px solid #fff;
                }
            }

            .left {
                &:after {
                    display: none;
                }
            }
        }
    }
}


.feature--50-50 {
    .item {
        @include breakpoint('tablet') {
            display: flex;
            justify-content: space-between;
            align-items: center;

            > * {
                width: 50%;
            }
        }

        .right {
            padding: 50px;
        }
    }
}



#page--categories {
    .gallery--grid--default {
        .items-container {
            justify-content: center;
        }
    }
}

.home-brands-container + .contentBlock--edge .items-container {
    justify-content: center !important;
}
