/* ============================================================================================== */
/* !VARIABLES */

$pyxus-yellow:                  #ffce31;
$pyxus-cyan:                    #00bbc9;
$pyxus-blue:                    #0d7cbf;
$pyxus-red:                     #ef6348;

$grey--xxxl:                    #f7f7f7;
$grey--xl:                      #999999;
$grey--light:                   #cccccc;
$grey:                          #606060;

$lime-green:                    #9bd439;


$font-size--default:            1.6rem;
$font-family--default:          'Khula', sans-serif;
$font-awesome:                  'Font Awesome 5 Free';



$phone:                         320px;
$phone-wide:                    480px;
$phablet:                       600px;
$tablet-small:                  640px;
$tablet:                        768px;
$tablet-medium:                 960px;
$tablet-wide:                   1024px;
$desktop-small:                 1200px;
$desktop:                       1400px;
$desktop-wide:                  1600px;


$site--max-width:               $desktop;

$navigation-breakpoint:         'tablet-medium';

$footer-breakpoint:             'tablet';

$navigation--hover-color:       $pyxus-yellow;

$transition--hover:             color 0.25s ease;



