/* ============================================================================================== */
/* !HOME */


#page--home {
    #container {
        padding-top: 300px;
        
        &:after {
            background-image: url('/images/common/mobile--bg--header.png');
            background-position: center top;
            
            @include breakpoint('tablet') {
                background-image: url('/images/home/home--header--bg.png');
                background-position: center top;
                padding-top: 170px;
            }
        }
    }
    
    .page--header {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        
        @include breakpoint('tablet') {
            height: 350px;
        }
        
        @include breakpoint('tablet-wide') {
            height: 300px;
        }
        
        P {
            line-height: 1.5;
        }
    }
    
    .page--title-container {
        .title {
            text-align: center;
            
            @include breakpoint('tablet') {
                text-align: right;
            }
        }
    }
}



#home--header {
    //height: 500px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    
    @include breakpoint('tablet') {
        height: 500px;    
    }
    
    .title {
        color: $pyxus-blue;
        //transform: translateY(-100%);
        line-height: 1.5;
        margin-bottom: 30px;
        
        @include breakpoint('tablet') {
            color: #fff;
        }
    }
}

#home--intro {
    @include breakpoint('tablet') {
        max-width: 50%;
    }
    
    P {
        line-height: 1.5;
    }
}

#home--stats {
    .item {
        margin-bottom: 50px;
        
        &:last-child {
            margin-bottom: 0;
        }
        
        @include breakpoint('tablet') {
            margin-bottom: 0;
        }
    }
}

#home--brands,
.home-brands-container {
    position: relative;
    z-index: 9;
    
    &:after {
        content: "";
        position: absolute;
        top: -300px;
        left: 0px;
        right: 0px;
        width: 100%;
        height: 1653px;
        background: url(/images/common/bg--orange.png) no-repeat center top;
        z-index: -1;
    } 

    h2 {
       color: white;
    }
}

#brands-right {
    position: relative;
    
    &:before {
        content: "";
        position: absolute;
        top: -300px;
        left: 0px;
        right: 0px;
        width: 100%;
        height: 1643px;
        background: url(/images/common/bg--orange--right.png) no-repeat center top;
        z-index: 1;
    }
    
}

#home--brands {
    color: #fff;
}
