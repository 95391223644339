/* ============================================================================================== */
/* !ABOUT US */

.section--about {

}

#page--about {
    #container {
        padding-top: 300px;
        
        &:after {
            background-image: url('/images/common/mobile--bg--header.png');
            background-position: center top;
            
            @include breakpoint('tablet') {
                background-image: url('/images/common/secondary--bg--header.png');
                background-position: center top;
                padding-top: 500px;
            }
            
            @include breakpoint('tablet-medium') {
                background-position: center top;
            }
        }
    }
}

#about--evolution {
    position: relative;
    z-index: 9;
    
    &:after {
        content: "";
        position: absolute;
        top: -300px;
        left: 0px;
        right: 0px;
        width: 100%;
        height: 1653px;
        background: url(/images/common/bg--orange--right.png) no-repeat center top;
        z-index: -1;
    } 
}
